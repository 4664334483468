.anadir {
    overflow-y: auto;
    overflow-x: hidden;
}

.div-container {
    width: 100%;
    min-height: 100%;
}

.div-container-columns{
    display: flex;
}

.div-container > * {
    margin: 5px;
}

.div-container-izq,
.div-container-der {
    position: relative;
    min-height: 90%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
}

.btn-add-estudio {
    width: 100%;
    padding: 10px;
}

.action-btns-form {
    width: 100%;
    height: max-content;
    margin: 15px 0;
    display: flex;
    justify-content: center;
    gap: 5px;
    grid-area: bottom;
}

.action-btns-form > * {
    padding: 20px 30px;
    font-size: 14pt;
}

.action-btns-form > :has(i){
    background: none;
    border: none;
    color: var(--main-color);
}

.action-btns-form > :has(i):hover {
    background-color: var(--main-color);
    color: var(--container-color);
    transition: all 500ms .25s;
    border-radius: 10px;
}

.action-btns-form > :not(:has(i)):hover {
    background-color: var(--main-color-light);
    color: var(--container-color);
    transition: all 500ms 0s;
}

.quit {
    font-size: 12pt;
    font-weight: bold;
    position: absolute;
    right: 5px;
    top: 2px;
    padding: 3px;
}

.quit:hover {
    cursor: pointer;
    background-color: rgb(162, 22, 22);
    color: wheat;
    border-radius: 3px;
}

.info i{
    color: #4c9ae4;
}

.info {
    font-size: 9pt;
}