.container-main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 70px);
    background-color: var(--bg-container-color);
    position: relative;
    z-index: 0;
}

div.main {
    position: absolute;
    width: 90%;
    border-radius: 10px;
    height: 90%;
    background-color: var(--container-color);
    padding: 20px;
    margin: 20px 0;
}

::-webkit-scrollbar {
    width: 10px;
    background: none;
}

::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    border-radius: 10px;
    border-right: 2px solid var(--color-blanco);
    border-left: 2px solid var(--color-blanco);
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
}

.action-btns {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    transform: translate(-50%, -50%);
    justify-content: space-between;
}

.action-btns a {
    height: 150px;
    width: 150px;
    text-align: center;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.action-btns a:hover {
    background-color: var(--main-color);
    color: var(--container-color);
    transition: all 500ms 0s;
    filter: drop-shadow(5px 5px 5px var(--main-color));
}