form {
    width: 25%;
    padding: 10px;
    background-color: var(--container-color);
    border-radius: 20px;
}

form h1 {
    text-align: center;
}

.wrap-input {
    position: relative;
    width: 100%;
}

.wrap-input > i {
    position: absolute;
    margin: 0 12px;
    right: 0;
    top: 25px;
}

.wrap-input ul {
    padding-left: 20px;
    font-size: 7pt;
}

.signIn-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
}