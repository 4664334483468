@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
#perfilImg{
    width: 100px;
}

.perfil, .perfil > .main{
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
}

.content main {
    width: 80%;
    overflow-y: hidden;
}

.content main > * {
    height: 100%;
}

.content main #configuracion *, .content main > * {
    padding: 10px;
}

.content main p {
    background-color: #dedede;
    padding: 5px;
    opacity: .7;
}

.content main #btn-borrarCuenta {
    padding: 10px;
    background: none;
    border: 2px solid var(--peligro);
    text-transform: uppercase;
    font-weight: bold;
    color: var(--peligro);
}

.content main #btn-borrarCuenta::before {
    content: '\F333';
    padding: 3px;
    font-family: "bootstrap-icons";
}

.content main #btn-borrarCuenta:hover::before {
    content: '\F332';
    padding: 3px;
    font-family: "bootstrap-icons";
}

.content main #btn-borrarCuenta:hover {
    cursor: pointer;
    background-color: var(--peligro);
    color: white;
}

.content main .grp-conf {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content main .grp-conf .toggle-btn {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.content main .grp-conf .toggle-btn input {
    opacity: 0;
    width: 0;
    height: 0;
}

.content main .grp-conf .toggle-btn .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.content main .grp-conf .toggle-btn .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.content main .grp-conf .toggle-btn input:checked+.slider {
    background-color: var(--main-color);
}

.content main .grp-conf .toggle-btn input:checked+.slider:before {
    transform: translateX(20px);
}

.content main .grp-conf .toggle-btn .slider.round {
    border-radius: 20px;
}

.content main .grp-conf .toggle-btn .slider.round:before {
    border-radius: 50%;
}

.toggle-aside {
    position: relative;
    display: none;
    height: max-content;
    background: none;
    border: none;
    padding-top: 10px;
    font-size: 20pt;
    margin-left: 10px;
}

.content aside {
    position: relative;
    padding: 20px;
    width: 150px;
    border-right: 1px solid black;
    font-size: 1em;
}

.content aside a {
    color: black;
    display: block;
    margin: 10px 0;
}

.content aside span{
    font-size: 10pt;
    text-align: center;
    width: 100%;
    display: block;
}