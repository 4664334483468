@media screen and (width < 1000px) {
    #logIn-form {
        width: 300px;
    }
    .signIn-footer {
        flex-direction: column;
        justify-content: left;
    }

    .signIn-footer * {
        margin: 5px 0;
    }

    #signIn-form input[type="submit"] {
        width: 100%;
    }

    .div-container-columns {
        flex-direction: column;
    }
}

@media screen and (width < 820px) {
    .content aside a {
        font-size: 8pt;
    }

}

@media screen and (width < 750px) {
    .grp {
        column-count: 1;
    }

    form {
        margin: 20px auto;
    }

    form > *{
        width: 100% !important;
    }

    #signIn-form {
        width: 300px;
    }

    .content aside {
        position: absolute !important;
        height: 100%;
        z-index: 3;
        left: -200px;
        transition: left 500ms 0s;
        background-color: var(--container-color);
    }

    .toggle-aside {
        display: block !important;
    }

    .perfil, .perfil > main {
        padding-left: 20px !important;
    }
}

@media screen and (width < 400px) {
    form {
        width: 100% !important;
    }
    h1{
        font-size: 20pt;
    }
    .action-btns {
        flex-direction: column;
        align-items: center;
    }

    .action-btns a {
        margin: 10px;
    }
}