.portfolio-div {
    background-color: var(--portfolio-color);
    width: 200px;
    padding: 5px;
    margin: 20px 0;
}

.portfolio-div:hover {
    cursor: pointer;
}

#ctxMenu {
    border-radius: 10px;
}

#ctxMenu ul {
    list-style: none;
}

#ctxMenu ul li {
    padding: 5px;
}

#ctxMenu ul li:hover{
    cursor: pointer;
    background-color: #ddd;
}