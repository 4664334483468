@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

:root {
    --bg-color-menu-hover: #393838;
    --header-elem: #fff;
    --header-menu-hide: #dedede;
    --input-color: #dedede;
    --portfolio-color: #dedede;
    --dark-input-color: #c2c2c2;
    --container-color: #fdfdfd;
    --bg-container-color: #ddd;
    --bg-input-container-color: #f5f5f5;
    --ctxMenu-bg-color: #c2c2c2;
    --main-color: #A655D7;
    --main-color-light: #c282e9;
    --error: red;
    --peligro: #ce2a2a;
    --success: #28A525;
    --bg-modal: #00000055;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Raleway", sans-serif;
}

.header {
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: relative;
    z-index: 1;
}

.header * {
    color: var(--header-elem);
}

.header > div {
    display: flex;
    gap: 10px;
}

.header i{
    font-size: 16pt;
}

.header i:hover {
    cursor: pointer;
}

a {
    text-decoration: none;
}

.header-hide-menu {
    position: absolute;
    right: 50px;
    top: 55px;
    width: max-content;
    display: none;
    flex-direction: column;
    height: max-content;
    background-color: var(--header-menu-hide);
    border-radius: 10px;
    filter: drop-shadow(2px 2px 5px black);
    z-index: 2;
}

/*redondea las esquinas superiores e inferiores de los enlaces de los extremos*/
.header-hide-menu a:first-child:hover{
    border-radius: 10px 10px 0 0;
}
.header-hide-menu a:last-child:hover{
    border-radius: 0 0 10px 10px;
}

.header-hide-menu a:first-child::after{
    content: '\F4E1';
    font-family: "bootstrap-icons";
}
.header-hide-menu a:first-child:hover::after{
    content: '\F4DA';
    font-family: "bootstrap-icons";
}
.header-hide-menu a:nth-child(2)::after{
    content: '\F3B9';
    font-family: "bootstrap-icons";
}
.header-hide-menu a:nth-child(2):hover::after{
    content: '\F3B8';
    font-family: "bootstrap-icons";
}
.header-hide-menu a:nth-child(3)::after{
    content: '\F425';
    font-family: "bootstrap-icons";
}
.header-hide-menu a:nth-child(3):hover::after{
    content: '\F424';
    font-family: "bootstrap-icons";
}
.header-hide-menu a:last-child::after{
    content: '\F44F';
    font-family: "bootstrap-icons";
}
.header-hide-menu a:last-child:hover::after{
    content: '\F44E';
    font-family: "bootstrap-icons";
}

.header-hide-menu::before {
    content: "";
    height: 30px;
    width: 30px;
    transform: rotate(45deg);
    background-color: var(--header-menu-hide);
    position: absolute;
    top: 0;
    right: 50px;
    border-radius: 2px;
    z-index: -1;
}

.header-hide-menu a {
    padding: 5px;
    color: black;
    display: flex;
    justify-content: space-between;
    gap: 3px;
}

.header-hide-menu > a:hover{
    background-color: var(--bg-color-menu-hover);
    color: white;
}

.header-hide-menu > a:hover > i{
    color: white;
}

.header-hide-menu ~ a {
    background-color: white;
    color: black;
    padding: 5px;
    border-radius: 10px;
    font-size: 9pt;
}

.header-hide-menu ~ a:hover {
    background-color: var(--dark-input-color);
    transition: all 250ms 0s;
}

.input-group {
    display: flex;
    flex-direction: column;
}

.input-group::after{
    content: '';
    display: block;
    margin-bottom: 20px;
}

.input-group-container {
    background-color: var(--bg-input-container-color);
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    position: relative;
}

input, textarea {
    outline: none;
    padding: 7px;
    background-color: var(--input-color);
    border: none;
    border-radius: 10px;
}

textarea{
    padding: 5px 20px;
    min-height: 75px;
}

.main-form {
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 70px);
}

form input[type="submit"] {
    background-color: var(--main-color) !important;
    color: white;
}

form a {
    color: var(--main-color);
    font-weight: bold;
}

#signIn-form{
    width: 50%;
}

.grp{
    column-count: 2;
}

.grp ul.error::after {
    content: '\A';
    display: block;
}

.grp .input-group {
    break-inside: avoid; /* Evita que los elementos se dividan entre columnas */
}

.error {
    column-span: all; /* Hace que la ul ocupe ambas columnas */
}

#logIn-form input[type="submit"]{
    width: 100%;
}

#signIn-form input[type="submit"] {
    width: 30%;
}

input[type="submit"]:hover {
    cursor: pointer;
}

input[type="checkbox"]{
    accent-color: var(--main-color);
}

.grp {
    margin: 20px 0;
}

.hide {
    display: none;
}

i.bi-plus {
    font-size: 20pt;
}

.error{
    color: var(--error);
}

.success {
    color: var(--success);
}

.error, .success {
    margin-top: 5px;
}

.error-inactivo, .exito-inactivo {
    display: none;
}

.modal-bg {
    position: relative;
    background-color: var(--bg-modal);
    height: calc(100vh - 70px);
    width: 100%;
}

.modal {
    background-color: var(--container-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
    margin: 50px auto;
    animation: slideDown 250ms 0s;
}

.modal h1{
    text-transform: uppercase;
}

.modal i{
    font-size: 60pt;
}

.modal button {
    border: none;
    padding: 10px;
    margin: 10px;
    color: white;
    min-width: 60px;
    border-radius: 5px;
}

button:hover{
    cursor: pointer;
}

.modal #btn-si{
    background-color: var(--success);
}

.modal #btn-si:hover{
    background-color: var(--container-color);
    color: var(--success);
    border: 2px solid var(--success);
    transition: all 250ms 0s;
}

.modal #btn-no {
    background-color: var(--peligro);
}

.modal #btn-no:hover {
    background-color: var(--container-color);
    color: var(--peligro);
    border: 2px solid var(--peligro);
    transition: all 250ms 0s;
}

.main, aside, .container-main, p{
    transition: background-color 500ms 0s;
}